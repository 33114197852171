ul,
li {
	text-align: left;
	list-style: none;
}

.chat {
	max-width: 500px;
	margin-bottom: 12px;
	line-height: 24px;
	padding: 10px 20px;
	border-radius: 25px;
	position: relative;
	color: white;
	text-align: center;
}

.message {
	display: flex;
	align-items: center;
}

.sent {
	flex-direction: row-reverse;
}

.sent p {
	color: white;
	background: #0b93f6;
	align-self: flex-end;
}

.received p {
	background: #e5e5ea;
	color: black;
}

img {
	width: 40px;
	height: 40px;
	border-radius: 50%;
	margin: 2px 5px;
}
